import { Page, Text, View, Document, Image, StyleSheet, Font, Link } from '@react-pdf/renderer';
import { HasValue } from '../Helper/JSHelper';
import { _fontCalibri, _fontCalibriBold, _fontCalibriItalic, _imgGreenFields, _imgIacLogoWhite, _imgIfacLogoLong } from '../Resources/Resources';

function PDFReceipt ({receipt}) {

    Font.register({family: "Calibri", src:_fontCalibri});
    Font.register({family: "Calibri", fontStyle:"normal", fontWeight:"bold", src:_fontCalibriBold});
    Font.register({family: "Calibri", fontStyle:"italic", fontWeight:"normal", src:_fontCalibriItalic});

    const styles = StyleSheet.create({
        page: {
          marginLeft:"20px",
          paddingRight:"40px",
          marginTop:"10px",
          marginBottom:"10px",
          paddingBottom:"10px",
          fontSize:"12px",
          fontFamily:"Calibri",
          height:"100%"
        },
         watermarkCancelled:{
          position:"fixed",
          bottom:"10%",
          left:"10%",
          transform:"rotate(-30deg)",
          color:"red",
          opacity:"0.4",
          fontWeight:"bold",
          fontSize:"90",
          top:"-100px"
        }
      });

    let hasDiscount = false;
    let discountText1 = "";
    let discountText2 = "";
    let discountText3 = "";
    
    if (HasValue(receipt.discount))
    {
      hasDiscount = true;

      const digitDiff = Math.round(receipt.amount).toString().length - Math.round(receipt.discount).toString().length;
      let spaces = "";
      for (let i = 1; i <= digitDiff; i++ )
      {
          spaces += "  ";
          if ( i % 2 == 0)
            spaces += " ";
      }

      discountText1 = `€${spaces}`;
      discountText2 = `${receipt.discount?.toLocaleString(undefined, { minimumFractionDigits: 2 })} `;
      discountText3 = `(€${receipt.discountNet?.toLocaleString(undefined, { minimumFractionDigits: 2 })} plus Vat @ ${receipt.vatRate}% of €${receipt.discountVat?.toLocaleString(undefined, { minimumFractionDigits: 2 })})`;
    }

    let factor = 1;
      if (!HasValue(receipt.clientAddress1))
        factor++;
      if (!HasValue(receipt.clientAddress2))
        factor++;
      if (!HasValue(receipt.clientAddress3))
        factor++;
      if (!HasValue(receipt.clientAddress4))
        factor++;
      if (!HasValue(receipt.clientAddress5))
        factor++;

      let variableSpace = 12 * factor + "px";

      var officeAddress= '';
if (HasValue(receipt.address1Office)){
  officeAddress+= receipt.address1Office;
}
if (HasValue(receipt.address2Office)){
  officeAddress+=', '+ receipt.address2Office;
}
if (HasValue(receipt.address3Office)){
  officeAddress+=', '+ receipt.address3Office;
}
if (HasValue(receipt.address4Office)){
  officeAddress+=', '+ receipt.address4Office;
}
if (HasValue(receipt.address5Office)){
  officeAddress+=', '+ receipt.address5Office;
}
if (HasValue(receipt.address6Office)){
  officeAddress+=', '+ receipt.address6Office;
}
if (HasValue(receipt.eircodeOffice)){
  officeAddress+=', '+ receipt.eircodeOffice;
}
var secondOfficeAddress=''
if (HasValue(receipt.phone1Office)){
  secondOfficeAddress+=receipt.phone1Office;
}
if (HasValue(receipt.officeEmail)){
  secondOfficeAddress+='|'+receipt.officeEmail;
}
if (HasValue(secondOfficeAddress))
{
  secondOfficeAddress+=('| www.ifac.ie');
}else{
  secondOfficeAddress='www.ifac.ie';
}             
   

  return (
    <Document title={`Receipt ${receipt.receiptNo}`}>
      <Page size={'A4'} style={styles.page}>    
        <View style={{ height: "100%" }}>
          <View>
            <View style={{minHeight:"50px"}}>
              <Image src={_imgIfacLogoLong}></Image>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: "15px", marginBottom: "20px" }}>
              <View style={{ width: "20%" }}></View>
              <Text>Ref:   {`${receipt.clientCode}/${receipt.officeCode}/${receipt.staffCode}`}</Text>
              <Text>Date: {new Date(receipt.receiptDate).toLocaleDateString("en-GB")}</Text>
            </View>
            <View style={{minHeight:"30px", backgroundColor: "#00ABC7", padding: "5px", paddingBottom: "2px", flexDirection: "row", justifyContent: "center", border: "1px" }}>
              <Text style={{ color: "white", fontSize: "20", fontWeight: "bold" }}>Receipt</Text>
            </View>

            <View style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end", marginTop: "5px", minHeight:"12px"  }}>
              <Text style={{minHeight:"12px"}}>Number: {receipt.receiptNo}</Text>
            </View>
            <View style={{marginLeft: "40px", minHeight:"84px"}}>
              <Text style={{minHeight:"12px"}}>{receipt.clientTitle} {receipt.clientName}</Text>
              {HasValue(receipt.clientAddress1) &&
                <Text style={{minHeight:"12px"}}>{receipt.clientAddress1}</Text>}
              {HasValue(receipt.clientAddress2) &&
                <Text style={{minHeight:"12px"}}>{receipt.clientAddress2}</Text>}
              {HasValue(receipt.clientAddress3) &&
                <Text style={{minHeight:"12px"}}>{receipt.clientAddress3}</Text>}
              {HasValue(receipt.clientAddress4) &&
                <Text style={{minHeight:"12px"}}>{receipt.clientAddress4}</Text>}
              {HasValue(receipt.clientAddress5) &&
                <Text style={{minHeight:"12px"}}>{receipt.clientAddress5}</Text>}
                <View style={{minHeight:variableSpace, height:variableSpace}}></View>
            </View>
            
          </View>
          <View style={{ flexDirection: "column", justifyContent: "flex-end" }}>
            <View style={{minHeight:"25px", backgroundColor: "#00ABC7", padding: "5px", justifyContent: "start", marginTop: "30px", marginBottom: "10px", border: "1px" }}>
              <Text style={{ color: "white", fontWeight: "bold" }}>Received with Thanks</Text>
            </View>
            <View style={{ flexDirection: "column", justifyContent: "space-between", height:"100%" }}>
                <View style={{ flexDirection:"column", justifyContent:"flex-start", height:"50%"}}>
                    <View style={{ flexDirection:"row", justifyContent:"flex-start", height:"100%"}}>                   
                        <View style={{ flexDirection: "column", justifyContent: "space-around", marginRight:"40px" }}>
                                <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                                  <Text style={{fontWeight: "bold"}}>Total Received:</Text>
                                  {
                                    hasDiscount &&
                                    <>
                                      <Text>Discount:</Text>
                                      <Text>TotalCredit:</Text>
                                    </>
                                  }
                                </View> 
                                <Text style={{fontWeight: "bold"}}>In respect of:</Text>
                                <Text style={{fontWeight: "bold"}}>Signed:</Text>
                        </View>                                    
                        <View style={{ flexDirection: "column", justifyContent: "space-around" }}>
                                <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                                  <Text>{`€${receipt.amount?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</Text>
                                  {
                                    hasDiscount &&
                                    <>
                                      <View style={{ flexDirection:"row", justifyContent:"flex-start"}}>
                                        <Text>{discountText1}</Text>
                                        <Text style={{textDecoration: "underline"}}>{discountText2}</Text>
                                        <Text>{discountText3}</Text>
                                      </View>
                                      <Text>{`€${receipt.totalCredit?.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</Text>
                                    </>
                                  }
                                </View> 
                                <Text>Invoice {receipt.invoiceNo}</Text>
                                <Text>___________________________</Text>
                        </View> 
                    </View> 
                    { HasValue(receipt.deletedDate) &&
                        <View style={styles.watermarkCancelled}>
                          <Text>CANCELLED {new Date(receipt.deletedDate).toLocaleDateString("en-GB")}</Text>
                        </View> 
                        }    
                    <View style={{alignItems:"center"}}>
                        <Text style={{fontWeight: "bold"}}>Thanking you for choosing Ifac. We appreciate your business.</Text>
                    </View>
                </View>           
                <View style={{ flexDirection: "column", justifyContent: "space-around", alignItems: "center", height: "15%" }}>
                    <Text style={{fontWeight: "bold", fontSize:"20px"}}>Anticipating. Informing. Growing.</Text>
                    <Text style={{fontWeight: "bold"}}>Accountants and financial advisors for forward-looking businesses in Ireland</Text>
                </View> 
                <View style={{ flexDirection:"column", justifyContent: "space-around", alignItems: "center" ,marginBottom:"10px"}}>             
                  <Text > {officeAddress}</Text>                            
                  <Text style={{fontSize:"10px"}} >{secondOfficeAddress} </Text>
              </View> 
            </View>
          </View>
        </View>
      </Page>
    </Document>
        );
}

export default PDFReceipt;